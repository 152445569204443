import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import Hero from '../../components/Hero';
import Subfooter from '../../components/Subfooter';
import Resource from '../../components/Resource';
import Image from '../../components/Image';
import CareerProfileCard from '../../components/CareerProfileCard';
import Button from '../../components/Button';

import '../../styles/pages/_classroom-instruction.scss';
import '../../styles/styles.scss';
import '../../styles/pages/_ccready.scss';
import OutboundLink from '../../components/OutboundLink';

const CareerFinder = () => {
  return (
    <Layout title="Career Finder">
      <Hero
        mediaSource={'header-Career-Profiles@2x.jpg'}
        className={'hero--large mb-0 pb-0'}
        color={'blue'}
      >
        <h1>CAREER FINDER</h1>
        <p>
          Find out what biotech career paths align with your students’ interests
          using the Futurelab+ interactive Career Finder tool.
        </p>
      </Hero>
      <Container>
        <Section>
          <h2 className="text-bold">
            What Biotech Careers Align with Your Students’ Interests?
          </h2>
          <Row className="mt-1 mb-3">
            <Column size={6}>
              <Image filename="img-your-interests-your-future@2x.jpg" />
            </Column>
            <Column size={6}>
              <h3 className="text-bold mt-0 mb-2">
                Their Interests, Their Future
              </h3>
              <p>
                Using the interactive Futurelab+ Career Finder, students answer
                a series of simple questions to discover what career path is
                right for them while learning about the wide variety of paths in
                the biotechnology industry, including: Research and Development,
                Food, Animal, and Environmental Science, Manufacturing, and
                more.
              </p>
              <OutboundLink
                to="/files/modules/career-finder/"
                target="_blank"
                rel="noopener noreferrer"
                class="button"
                data-click-type="Module Launch"
                data-click-name="GEN-CCR-Career-Finder"
              >
                Launch Career Finder
              </OutboundLink>
            </Column>
          </Row>
          <Row>
            <h3 className="text-bold mt-0">Explore Biotechnology Careers</h3>
            <p>
              Introduce students to a variety of subject matter experts working
              in the field of biotechnology. Get an inside look at the dynamic
              teamwork and creative STEM problem-solving that goes on every day
              in the lab and beyond, as students find out how these unique
              individuals ignited their passion for biotech and created
              rewarding careers.
            </p>
          </Row>
        </Section>

        <Section>
          {/* First row of Career profile cards. Actions property sets the title of the card */}
          <Row>
            <CareerProfileCard
              className={'profile-title-link'}
              to="/college-career-readiness/career-profiles/profile-quality-assurance"
              asCard
              img="img-career-profiles-senior-supervisor-qa@2x.jpg"
              description="Quality Assurance professionals in the field of biotech ensure that the manufacturing of medicine is done safely, effectively, and in compliance with standards. Olajuwon alternates seamlessly between troubleshooting on the manufacturing floor to collaborating in a brainstorming session in the office, thanks to his strong communication and problem-solving skills."
              actions={[
                {
                  label: 'Senior Supervisor of Quality Assurance',
                  type: 'link',
                  path:
                    '/college-career-readiness/career-profiles/profile-quality-assurance',
                },
              ]}
            />
            <CareerProfileCard
              className={'profile-title-link'}
              asCard
              img="img-career-profiles-senior-scientist-development-sciences@2x.jpg"
              description="Senior Scientists in Development Sciences are responsible for getting innovative biotech medications from idea to manufacturing, ensuring that any risks to humans have been identified. Danielle’s perseverance and expert knowledge of Chemistry enable her to overcome challenges in the manufacturing process and turn failures into personal improvements."
              actions={[
                {
                  label: 'Senior Scientist in Development Sciences',
                  type: 'link',
                  path:
                    '/college-career-readiness/career-profiles/profile-developmental-science',
                },
              ]}
            />
            <CareerProfileCard
              className={'profile-title-link'}
              asCard
              img="img-career-profiles-scientist-data-sciences@2x.jpg"
              description="Principal Scientists in Development Sciences are like “disease detectives” who interpret a wide variety of real-world data and search for patterns that can be used to improve healthcare and medication delivery. Preet harnesses the power of data to gain better insight into the way patients experience the medical system, as she studies how medications affect people differently."
              actions={[
                {
                  label: 'Principal Scientist in Development Sciences',
                  type: 'link',
                  path:
                    '/college-career-readiness/career-profiles/profile-data-science',
                },
              ]}
            />
          </Row>
          {/* Second row of career cards */}
          <Row>
            <CareerProfileCard
              className={'profile-title-link'}
              asCard
              img="img-career-profiles-senior-project-leader@2x.jpg"
              description="Senior Project Leaders of Companion & Clinical Diagnostics Development work at the forefront of Precision Medicine, using data and patient feedback to improve the effectiveness of a medication by tailoring it to a certain person or group. Doris developed her love of nature as a child into a fascination with DNA, and she brings that same curiosity to her work and collaborations."
              actions={[
                {
                  label:
                    'Senior Project Leader of Companion & Clinical Diagnostics Development ',
                  type: 'link',
                  path:
                    '/college-career-readiness/career-profiles/profile-companion-diagnostic-care',
                },
              ]}
            />
            <CareerProfileCard
              className={'profile-title-link'}
              asCard
              img="img-career-profiles-diversity-inclusion-lead@2x.jpg"
              description="Diversity & Inclusion Leads are responsible for helping to create an inclusive work environment in which all employees feel comfortable being themselves. As a curious and open-minded youth, Doran gravitated toward a science career, but ultimately she discovered her ideal niche working in a role outside of science, supporting the biotech product development lifecycle by ensuring her colleagues can thrive."
              actions={[
                {
                  label: 'Diversity & Inclusion Lead',
                  type: 'link',
                  path:
                    '/college-career-readiness/career-profiles/profile-diversity-and-inclusion',
                },
              ]}
            />
            <CareerProfileCard
              className={'profile-title-link'}
              asCard
              img="img-career-profiles-senior-manager-patient-advocacy@2x.jpg"
              description="Senior Managers of Patient Advocacy Relations work with patient advocacy organizations to understand the patient experience and use data to inform better medical products and services. While not initially interested in STEM as a youth, Katie found a passion for patient advocacy and ended up finding a role where she has the chance to positively impact thousands of lives through her work."
              actions={[
                {
                  label: 'Senior Manager of Patient Advocacy Relations',
                  type: 'link',
                  path:
                    '/college-career-readiness/career-profiles/profile-patient-advocacy',
                },
              ]}
            />
          </Row>
          {/* College and Career Coaching*/}
          <Row className="mt-0 pt-0">
            <Section className="mt-0 pt-0">
              <h3 className="text-bold mt-0" id="coaching">
                College and Career Coaching
              </h3>
              <p className="text-bold">
                Connect with Biotechnology Professionals in Your Community
              </p>
              <p>
                Connections to industry are a critical component to Futurelab+,
                allowing educators and students to infuse the curriculum with
                real world applications. The below resources provide an overview
                of ways to connect, virtually and in-person, with volunteer
                biotech professionals along with resources to prepare students
                for thoughtful discussions when going through the informational
                interview process.
              </p>
            </Section>
            <Column size={4}>
              <Resource
                className="profile-title"
                img="img-ext-res-edu-employee-guide@2x.jpg"
                title="OVERVIEW"
                description="Provide students with a lens into the diverse and dynamic people powering today’s biotechnology industry through opportunities that bring real-world professionals into your classroom. This overview provides an overview of the importance that corporate volunteerism plays in connecting students with careers in the biotechnology industry."
                withBorder={false}
                asCard
                actions={[
                  {
                    label: 'Learn More',
                    type: 'download',
                    path:
                      '/pdfs/career-readiness/Gen-CR-Career-Coaching-Overview.pdf',
                  },
                ]}
              />
            </Column>
            <Column size={4}>
              <Resource
                className="profile-two-line-title"
                img="img-interview-prep-teachers-volunteers@2x.jpg"
                subject="Educator and Volunteer Guide"
                title="TWO-WAY INTERVIEW PREP"
                description="Prepare students for their next informational interview by stepping into the role of “interviewer” and role-playing through key questions and answers. This guide can be used by educators or provided as an activity to employee volunteers who are connecting with your students one on one."
                withBorder={false}
                asCard
                actions={[
                  {
                    label: 'Learn More',
                    type: 'download',
                    path:
                      '/pdfs/career-readiness/Gen-CR-Career-Coaching-Volunteer-Guide.pdf',
                  },
                ]}
              />
            </Column>
            <Column size={4}>
              <Resource
                className="profile-two-line-title"
                img="img-interview-prep-students@2x.jpg"
                subject="Student Guide"
                title="TWO-WAY INTERVIEW PREP"
                description="Informational interviews are a great first-step to building networks and understanding different types of jobs. Use this interview preparation guide to ensure a successful informational interview."
                withBorder={false}
                asCard
                actions={[
                  {
                    label: 'Learn More',
                    type: 'download',
                    path:
                      '/pdfs/career-readiness/Gen-CR-Career-Coaching-Student-Guide.pdf',
                  },
                ]}
              />
            </Column>
          </Row>
        </Section>
      </Container>
    </Layout>
  );
};

export default CareerFinder;
